import { $_app_server } from '@/_helpers'

export const networkService = {
  loadMyNetwork,
  loadNetwork
}

let func = (response) => response.data

function loadMyNetwork(projectid) {
  return $_app_server.get(`network/my?projectid=${projectid}`).then(func)
}

function loadNetwork(id) {
  return $_app_server.get(`network/${id}`).then(func)
}