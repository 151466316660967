import { $_app_server } from '@/_helpers'

export const reportService = {
  getReportByPointId,
  sendReportData,
  sendPreReport,
  sendReport,
  deletePhoto,
  deleteDataPhoto
}

let func = (response) => response.data

function getReportByPointId(tradepointid) {
  return $_app_server.get(`report/point/${tradepointid}`).then(func)
}

function sendReportData(data) {
  return $_app_server.post(`report/data/save`, data).then(func)
}

function sendPreReport(data) {
  return $_app_server.post(`report`, data).then(func)
}

function sendReport(report) {
  return $_app_server.get(`report/${report.uuid}/products`).then(func)
}

function deletePhoto(uuid, data) {
  return $_app_server.delete(`report/${uuid}/photo/${data}`).then(func)
}

function deleteDataPhoto(uuid, data, product) {
  return $_app_server.delete(`report/${uuid}/photo/${data}/product/${product}`).then(func)
}