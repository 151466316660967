import { $_app_server } from '@/_helpers'

export const motivationalReportService = {
  loadMyMotivation,
  createDmp,
  loadDmpCategories,
  sendReportDmp,
  loadDmpReport,
  deleteMotivationalPhoto
}

let func = (response) => response.data

function loadMyMotivation(projectId, networkId, tradepointId) {
  let query = `?project_id=${projectId}&networkid=${networkId}&tradepointid=${tradepointId}`
  return $_app_server.get(`motivationalReport/myMotivational${query}`).then(func)
}

function createDmp(motivationalDmp, motivationalReportId) {
  return $_app_server.post(`motivationalReport/createMotivationalDmp/${motivationalReportId}`,motivationalDmp).then(func)
}

function loadDmpCategories() {
  return $_app_server.get('motivationalReport/dmpCategories').then(func)
}

function sendReportDmp(motivationalDmpId, obj) {
  return $_app_server.post(`motivationalReport/motivationaldmp/${motivationalDmpId}/save`, obj).then(func)
}

function loadDmpReport(motivationalDmpId) {
  return $_app_server.get(`motivationalReport/dmp/${motivationalDmpId}`).then(func)
}

function deleteMotivationalPhoto(reportId, imageUuid) {
  return $_app_server.delete(`motivationalReport/${reportId}/photo/${imageUuid}`).then(func)
}