export const network = {
  namespaced: true,
  state: {
    selectedNetwork:null
  },
  getters: {
  },
  actions: {
    
  },
  mutations: {
    setSelectedNetwork(state,network){
      state.selectedNetwork = network
    }
  }
}