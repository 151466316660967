export const category = {
  namespaced: true,
  state: {
    selectedCategory:null
  },
  getters: {
  },
  actions: {
    
  },
  mutations: {
    setSelectedCategory(state,c){
      state.selectedCategory = c
    }
  }
}