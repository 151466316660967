import { $_app_server } from '@/_helpers'

export const productService = {
  loadPointProducts,
  loadPointActionProducts,
  countPointActionProducts,
  getProductReportData
}

let func = (response) => response.data

function loadPointProducts(project_id, tradepointid, categoryid) {
  let query = `?project_id=${project_id}&tradepointid=${tradepointid}&categoryid=${categoryid}`
  return $_app_server.get(`product/getpointproducts${query}`).then(func)
}

function loadPointActionProducts(project_id, tradepointid) {
  let query = `?project_id=${project_id}&tradepointid=${tradepointid}`
  return $_app_server.get(`product/getpointactionproducts${query}`).then(func)
}

function countPointActionProducts(project_id, tradepointid) {
  let query = `?project_id=${project_id}&tradepointid=${tradepointid}`
  return $_app_server.get(`product/countpointactionproducts${query}`).then(func)
}

function getProductReportData(projectid, tradepointid, id) {
  return $_app_server.get(`product/getproductreportdata?projectid=${projectid}&tradepointid=${tradepointid}&id=${id}`).then(func)
}