import { $_app_server } from '@/_helpers'

export const categoryService = {
  getpointcategories,
  loadCategory
}

let func = (response) => response.data

function getpointcategories(id, project) {
  return $_app_server.get(`category/pointcategories/${id}?project_id=${project}`).then(func)
}

function loadCategory(id) {
  return $_app_server.get(`category/${id}`).then(func)
}