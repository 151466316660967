<template lang="pug">
    v-app
        v-main(app)
            router-view
</template>



<script>

    export default {
        components: {
        }
    }
</script>

