export const project = {
  namespaced: true,
  state: {
    selectedProject:null
  },
  getters: {
  },
  actions: {
    
  },
  mutations: {
    setSelectedProject(state,project){
      state.selectedProject = project
    }
  }
}