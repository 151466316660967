import EmptyLayout from '@/layouts/EmptyLayout.vue'

const Login = () => import('@/pages/access/Login.vue')
//const Registration = () => import('@/pages/access/Registration.vue')

export default {
    path: '/access',
    component: EmptyLayout,
    children: [
        {
            path: '',
            name:'Авторизация',
            component: Login
        }/*,
        {
            path: 'register',
            name:'Регистрация',
            component: Registration
        }*/
    ]
}