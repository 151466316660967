import { $_app_server } from '@/_helpers'

export const tradepointService = {
  getmytradepoints,
  loadPoint
}

let func = (response) => response.data

function getmytradepoints(projectid, networkid) {
  return $_app_server.get(`tradepoint/getmytradepoints?projectid=${projectid}&networkid=${networkid}`).then(func)
}

function loadPoint(id) {
  return $_app_server.get(`tradepoint/${id}`).then(func)
}