export let Role = {
  admin: 'admin'
}

export let NavItems = [
  {
    name: 'Проекты',
    path: '/project',
    icon: 'mdi-store'
  }
]