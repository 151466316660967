import Vue from 'vue'
import Vuex from 'vuex'

import { authentication } from './_store/authentication.module'
import { project } from './_store/project.module'
import { network } from './_store/network.module'
import { tradepoint } from './_store/tradepoint.module'
import { category } from './_store/category.module'
import { pointreport } from './_store/pointreport.module'
import { motivationalReport } from './_store/motivationalReport.module'


Vue.use(Vuex)


const store =  new Vuex.Store({
  state: {
    appBarTitle:'',
    appBackLink:null
  },

  getters: {
    
  },

  mutations: {
    setAppBarTitle(state, title){
      state.appBarTitle = title
    },
    setAppBackLink(state, link){
      state.appBackLink = link
    }
  },

  actions: {
    
  },

  modules: {
    authentication,
    project,
    network,
    tradepoint,
    category,
    pointreport,
    motivationalReport
  }
})

export default store