import { config, $_app_server } from '@/_helpers'
import axios from "axios"

export const userService = {
  login,
  logout,
  get
}

function login (username, password) {
  const { id: client_id, secret: client_secret } = config.client
  const security = `grant_type=password&client_id=${client_id}&client_secret=${client_secret}&username=${username}&password=${password}`
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Authorization': 'Basic ' + btoa(client_id + ':' + client_secret)
  }
  
  return axios.post(config.authUrl, security, { headers })
  .then(response => {
    let { access_token } = response.data
    $_app_server.defaults.headers.common.Authorization = 'Bearer ' + access_token
    return response.data
  })
  .catch(error => {
    if (error.response.status === 401) {
      logout()
      location.reload(true)
      return false
    }
  })
}

function logout() {
  $_app_server.defaults.headers.common.Authorization = null
  localStorage.removeItem('access_token')
  localStorage.removeItem('grants')
  localStorage.removeItem('user')
  location.reload(true)
}

function get() {
  return $_app_server.get("user/get")
    .then(response => {
        return response.data
    })
}