import { $_app_server } from '@/_helpers'

export const projectService = {
  getMyProjects,
  loadProject
}

let func = (response) => response.data

function getMyProjects() {
  return $_app_server.get("project/my").then(func)
}

function loadProject(id) {
  return $_app_server.get(`project/${id}`).then(func)
}