import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VuetifyMask from "vuetify-mask";
import VNumeric from 'vuetify-numeric/vuetify-numeric.umd'

import VueRouter from 'vue-router'
import store from './store'

// router setup
import routes from './routes/routes'


Vue.config.productionTip = false

// plugin setup
Vue.use(VueRouter);
Vue.use(VuetifyMask);
Vue.use(VNumeric);

// configure router
const router = new VueRouter({
  routes,
  linkActiveClass: 'routeactive',
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let title = to.meta.title || to.meta.label || to.name;
  document.title = title
  
  store.commit('setAppBarTitle',title)
  if (to.meta && to.meta.breadcrumb && to.meta.breadcrumb.length > 0) store.commit('setAppBackLink',to.meta.breadcrumb[to.meta.breadcrumb.length-1])
  else store.commit('setAppBackLink',null)
  
  const loggedIn = localStorage.getItem('access_token')
  const publicPages = ['/', '/home', '/access']
  const authRequired = !publicPages.includes(to.path)
  if (!loggedIn && authRequired) {
    next('/')
  }

  next()
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')