import { userService } from '@/_services'
//import Vue from 'vue'

const user = JSON.parse(localStorage.getItem('user')) || {};
const grants = JSON.parse(localStorage.getItem('grants')) || [];
//const initialState = user ? { status: { loggedIn: true }, user, grants: null } : { status: {}, user: null, grants: null };

export const authentication = {
  namespaced: true,
  state: {
    status: {},
    user,
    grants
  },
  getters: {
    hasRole: state => role => {
      return state.grants.some(grant => grant.grantType === role);
    }
  },
  actions: {
    login({commit}, data) {
      return userService.login(data.username, data.password)
    },
    getUser({ commit }) {
      userService.get().then(user => {
        localStorage.setItem('user', JSON.stringify(user))
        commit('loginSuccess',user);
      })
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
      state.grants = null
    },
    logout(state) {
      state.status = {};
      state.user = null;
      state.grants = null
    },
    setGrants(state, grants) {
      state.grants = grants
    }
  }
}