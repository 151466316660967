import { motivationalReportService } from '@/_services'

export const motivationalReport = {
  namespaced: true,
  state: {
    selectedMotivationalReport: null,
    motivationalDmps: [],
    selectedDmp: null,
    dmpCategories: [],
  },
  getters: {

  },
  mutations: {
    setSelectedMotivationalReport(state, report){
      state.selectedMotivationalReport = report
    },

    setMotivationalDmps(state, list){
      state.motivationalDmps = list
    },

    addMotivationalDmp(state, dmp){
      state.motivationalDmps.push(dmp)
    },

    setSelectedDmp(state, dmp){
      state.selectedDmp = dmp
    },

    setDmpCategories(state, list){
      state.dmpCategories = list
    }
  },

  actions: {
    loadMotivationalReport({ commit }, { projectId, networkId, tradePointId }) {
        motivationalReportService.loadMyMotivation(projectId, networkId, tradePointId).then(data => {
        commit('setSelectedMotivationalReport', data)
        commit('setMotivationalDmps', data.motivationalDmp)
      })
    },

    addMotivationalDmp({ commit }, { motivationalDmp, motivationalReportId }) {
      motivationalReportService.createDmp(motivationalDmp, motivationalReportId).then(data => {
        commit('addMotivationalDmp', data)
      })
    },

    loadCategories({ commit }) {
      motivationalReportService.loadDmpCategories().then(data => {
        commit('setDmpCategories', data)
      })
    },

    saveDmpReport({ dispatch }, { motivationalDmpId, report}) {
      motivationalReportService.sendReportDmp(motivationalDmpId, report).then(() => {
        dispatch('loadCategories')
      })
    }
  },
}