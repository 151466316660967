export const pointreport = {
  namespaced: true,
  state: {
    selectedPoint:null
  },
  getters: {
  },
  actions: {
    
  },
  mutations: {
    setSelectedPoint(state,point){
      state.selectedPoint = point
    }
  }
}